.tenantContactsTable {
  * {
    margin-top: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
  }

  li {
    display: block;
  }

  hr {
    border-top: 1px solid #b1b4b6;
    border-radius: 0;
    margin-top: 10px;
  }

  &-phoneNumberGrid {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #b1b4b6;
    padding: 15px 0;
    margin: 0;

    > div:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  li:not(:last-child) .tenantContactsTable-contact {
    margin-bottom: 45px;
  }

  &-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -10px;

    > * {
      margin-bottom: 10px;
    }
  }

  &-button {
    display: block;
    background: transparent;
    border: none;
    text-decoration: underline;
    padding: 0;
    color: #333;

    &:not(:disabled) {
      cursor: pointer;
    }

    &.disabled {
      color: #888;
    }
  }

  &-buttonGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > button:first-child {
      margin-bottom: 5px;
    }
  }

  &-phoneSubType {
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 5px;
  }

  &-description {
    background-color: #f9f9f9;
    padding: 5px;
    border-radius: 3px;
    color: #333;
  }
}

@media (min-width: 600px) {
  .tenantContactsTable {
    &-buttonGroup {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      > button {
        margin-bottom: 0;
      }
    }
  }
}
