.filter-collapsible {
  background-color: #b1b4b6;
  padding: 10px 20px;
  width: auto;
  margin: 0;
}
.filter-options {
  box-shadow: inset 0 0 0 1px #b1b4b6;
  margin-top: 0;
}
.selected-filters {
  background-color: repairs-hub-colour('lighter-grey');
  box-shadow: inset 0 0 0 1px #b1b4b6;
}
.filter-tag {
  background-color: repairs-hub-colour('white');
  border: 1px solid repairs-hub-colour('black');
  display: inline-block;
  margin-top: 5px;
  padding: 5px;
  text-decoration: none;
}
.filter-tags {
  margin-top: 0px;
  li {
    display: inline-block;
    margin-right: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .filter-container {
    width: 100%;
    margin: 0 0 5px;
  }
}

.filter-remove-button {
  cursor: pointer;
  border: none;
  background-color: white;
  float: right;
  margin-top: 3px;
}
