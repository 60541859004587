.operative-work-order {
  .person-alert {
    &--icon {
      background: repairs-hub-colour('dark-red');
      border: 3px solid repairs-hub-colour('dark-red');
    }

    &--text {
      color: repairs-hub-colour('dark-red');
    }
  }

  .align-grid-column {
    margin-top: 0rem;
  }

  .priority-text {
    text-transform: capitalize;
    font-size: 20px;
  }

  .property-name {
    font-weight: 600;
  }

  .work-order-information {
    background-color: repairs-hub-colour('light-grey');
    padding: 10px;
    padding-top: 5px;
  }
}

.truncate-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
}
.truncate-line-3 {
  -webkit-line-clamp: var(--line-clamp, 3);
}

.truncate-line-5 {
  -webkit-line-clamp: var(--line-clamp, 5);
}

.update-task-quantity {
  width: 50%;
}

.operative-work-order-task-and-sors-table {
  .lbh-heading-h3 {
    text-align: right;
  }
  .background-wrap-color {
    background-color: repairs-hub-colour('lighest-lbh-grey');
    padding: 10px;
  }
}
