.custom-file-input {
  &::file-selector-button {
    width: 0;
    padding: 0;
    visibility: hidden;
  }

  &::before {
    content: 'Choose file(s)';
    display: inline-block;
    padding: 13px 22px 11px;
    border: 1px solid #00664f;
    color: #00664f;
    background: #ffffff;
    border-radius: 4px;
    font-size: 19px;
    font-weight: 400;
    line-height: 23px;

    cursor: pointer;
  }

  &:hover::before {
    background: #00513f;
    color: #fff;
  }

  &::focus:not(:active):not(:hover)::before {
    border-color: #ffc845;
    color: #0b0c0c;
    background-color: #ffc845;
    box-shadow: 0 2px 0 #0b0c0c;
  }
}

.photoViewList {
  &-ul {
    display: flex;
    flex-direction: column;
  }

  &-li {
    display: block;
    padding: 0px;
    margin: 0px 0px 30px;
  }

  &-photoGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-photoGroupContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: calc(-15px + 30px) 0 0 -15px;
  }

  &-photoGroupItem {
    margin: 15px 0 0 15px;
    cursor: pointer;
  }
}

.photoUploadPreview {
  display: flex;
  flex-wrap: wrap;
  margin: calc(-15px + 30px) -15px 30px 0;

  &-container {
    margin: 15px 15px 0 0;
    width: 150px;
  }

  &-imageContainer {
    margin-top: 0;
    border: 2px solid #e6e6e6;
    width: 150px;
    height: 150px;
    box-sizing: border-box;
  }

  &-image {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &-removeButton {
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: 5px;
    display: block;
    background: none;
    border: none;
    text-decoration: underline;
    color: #025ea6;
    text-align: right;
    width: 100%;
    padding: 0;
    cursor: pointer;
  }
}

.updateDescriptionButton {
  margin-top: 15px;
  font-size: 16px !important;
}
