.date-picker-container {
    display: flex;
    justify-content: space-evenly;
    gap: -1rem;
    font-family: 'Open Sans', sans-serif;
    select {
        font-size: 1.5rem;
        margin-top: 0;
        border: 2px solid black;
        outline: none; 
        transition: box-shadow 0.3s, border-color 0.3s;
        font-family: 'Open Sans', sans-serif;
    }
    select:focus {
        border-color: black; 
        box-shadow: 0px 0px 0px 4px #FFDD00;
    }
    select:active {
        border-color: black;
        box-shadow: 0px 0px 0px 4px #FFDD00;
    }
}