.operative-work-order-list-item {
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  &--active {
    border-top: 8px solid repairs-hub-colour('dark-green');
    border-bottom: 2px solid repairs-hub-colour('dark-green');
    border-right: 2px solid repairs-hub-colour('dark-green');
    border-left: 2px solid repairs-hub-colour('dark-green');
  }

  &--inactive {
    background-color: repairs-hub-colour('light-grey');
  }

  .lbh-tag {
    font-weight: 600;
    vertical-align: top;
  }

  .appointment-time {
    font-size: 24px;
  }

  .appointment-details {
    p {
      font-size: 18px;
    }
  }
}
