.mobile-working-title-banner {
  padding: 8px 0 8px 0;
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
  text-align: center;
  background-color: repairs-hub-colour('dark-green');

  h1,
  h2 {
    color: repairs-hub-colour('white');
    line-height: normal;
  }
}
