.arrow {
  border: solid repairs-hub-colour('black');
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
