.modal-container {
  position: fixed;
  z-index: 999;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgb(0, 0, 0, 0.65);

  .modal-panel {
    background-color: repairs-hub-colour('dark-green');
    border: 2px solid white;

    width: 85%;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .close-icon-container svg {
      float: right;
      margin: 14px 10px;
    }

    .message {
      padding-top: 15px;
      text-align: center;
      font-weight: 600;
      margin: 60px 20px;
      color: rgb(255, 255, 255, 1);
    }
  }
}
