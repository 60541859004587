#new-sor-code-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
}

@media (min-width: 800px) {
  #new-sor-code-container {
    grid-template-columns: 1fr 1fr 1fr;

    > * {
      margin-top: 0;
    }
  }
}
