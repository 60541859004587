.env {
  &-name {
    color: white;
    display: flex;
    align-content: center;
    flex-wrap: wrap;

    span {
      padding: 0 5px 0 5px;
    }
  }

  &-development span {
    background-color: repairs-hub-colour('dark-green');
  }

  &-staging span {
    background-color: repairs-hub-colour('orange');
  }

  &-localdev span {
    background-color: repairs-hub-colour('orange');
  }
}

.text-for-env-note {
  background-color: red;
  text-align: center;
}
