.display-flex {
  display: flex;
  &.align-items-center {
    align-items: center;
  }
  &.position-relative {
    position: relative;
  }
}

.position-absolute {
  position: absolute;
  &.right-negative-3 {
    right: -3%;
  }
}

.display-inline {
  display: inline;
}

.text-align-left {
  text-align: left;
}

.float-right {
  float: right;
}

.min-height-100 {
  min-height: 100px;
}
.min-height-120 {
  min-height: 120px;
}

.border {
  &-top-black {
    border-top: 2px solid black;
  }

  &-bottom-grey {
    border-bottom: 2px solid lbh-colour('lbh-border');
  }

  &-none {
    border: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}
