.lbh-main-wrapper {
  @media print {
    padding: 0;
  }

  @include govuk-media-query($until: tablet) {
    padding-top: 0;
  }
}

.lbh-body {
  @include govuk-media-query($until: tablet) {
    margin-top: 1em;
  }
}
