.circle-tag {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 0.4rem;
  margin-left: -1.5rem;
  margin-bottom: -1.3rem;
  display: flex;
  background-color: repairs-hub-colour('blue');

  &--status-in-progress {
    background-color: repairs-hub-colour('blue');
  }
  &--status-completed,
  &--status-locked {
    background-color: repairs-hub-colour('white');
    border: 1px solid black;
  }
  &--status-cancelled {
    background-color: repairs-hub-colour('grey');
  }
  &--status-pending-approval,
  &--status-variation-pending-approval,
  &--status-authorisation-pending-approval {
    background-color: repairs-hub-colour('yellow');
  }
  &--status-variation-approved {
    background-color: repairs-hub-colour('dark-green');
  }
  &--status-variation-rejected {
    background-color: repairs-hub-colour('dark-red');
  }
  &--status-no-access {
    background-color: repairs-hub-colour('grey');
  }
}
