.updated-tasks-table {
  tbody {
    vertical-align: top;
  }

  td {
    .lbh-tag {
      &--status-reduced {
        background-color: repairs-hub-colour('yellow');
        color: repairs-hub-colour('black');
      }
      &--status-increased {
        background-color: repairs-hub-colour('dark-red');
        color: repairs-hub-colour('white');
      }
      &--status-new {
        background-color: repairs-hub-colour('blue');
        color: repairs-hub-colour('white');
      }
      &--status-unchanged {
        background-color: repairs-hub-colour('grey');
        color: repairs-hub-colour('black');
      }
    }
  }
}
