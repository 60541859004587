$govuk-assets-path: '~govuk-frontend/govuk/assets/';
$govuk-font-family: 'GDS Transport', arial, sans-serif !important;

@import 'lbh-frontend/lbh/all';

@import 'colours';
@import 'layout';
@import 'show-env-names';
@import 'main';
@import 'typography';
@import 'button';

@import 'components/header';
@import 'components/property-details';
@import 'components/work-orders/operatives';
@import 'components/work-orders/print';
@import 'components/work-orders/rate-schedule-item';
@import 'components/work-orders/tasks';
@import 'components/work-orders/work-orders';
@import 'components/tabs';
@import 'components/notes';
@import 'components/pagination';
@import 'components/appointments';
@import 'components/filter';
@import 'components/sor-status';
@import 'components/warning-info-box';
@import 'components/arrow';
@import 'table';
@import 'components/circle-tag';
@import 'components/mobile-working/mobile-menu';
@import 'components/mobile-working/mobile-working-title-banner';
@import 'components/mobile-working/date-picker';
@import 'components/mobile-working/mobile-work-order-container.scss';
@import 'components/operative/operative-work-order-list-item';
@import 'components/operative/work-order';
@import 'components/tabs-version-two.scss';
@import 'components/grid';
@import 'components/info-modal';
@import 'components/mobile-working/login';
@import 'components/back-office.scss';
@import '@reach/dialog/styles.css';
@import 'components/contact-details-table.scss';
@import 'components/photos.scss';
@import 'components/checkboxSmall.scss';

// Form layout consistancy changes
// only apply to specific forms

.photos-tab,
.close-work-order-form,
.mobile-working-close-work-order-form,
.close-work-order-confirmation-form {
  .lbh-label:not(.govuk-radios__label) {
    @extend .govuk-fieldset__legend;
    @extend .lbh-fieldset;
  }

  .operatives-group {
    margin-top: 0px;
    margin-bottom: 15px;

    .lbh-label,
    .smv-read-only--label {
      font-size: 1rem !important;
      line-height: 1.25rem !important;
    }
  }

  .operatives-group .operative-datalist,
  .select-percentage .lbh-form-group {
    margin-bottom: 0 !important;
  }

  .lbh-label {
    // add margin to textarea when label above
    + .lbh-textarea,
    + .govuk-input,
    + .lbh-select {
      margin-top: 10px !important;
    }
  }

  .lbh-fieldset,
  .lbh-label {
    margin-top: inherit;
  }

  h1,
  h2 {
    margin-bottom: 30px;
  }

  .govuk-button,
  .lbh-button {
    margin-top: 0;
  }

  .govuk-form-group,
  .lbh-form-group {
    margin-bottom: 60px !important;
    margin-top: 0;
  }
}

.lbh-announcement {
  padding: 15px 0;
}

.photos-tab {
  .lbh-form-group {
    margin-bottom: 30px !important;
  }

  .photos-tab-upload #description-form-group {
    margin-bottom: 0 !important;
  }
}

.lbh-announcement {
  padding: 15px 0;
}

.latest-changes {
  background: #e6e6e6;
  padding: 30px;
  border-left: 6px solid #00664f;

  img {
    width: 100%;
    height: auto;
    margin-top: 0;
  }

  .download-container {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 800px) {
  .latest-changes {
    img {
      max-width: 500px;
    }
  }
}
