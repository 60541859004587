.hackney-property-alerts {
  color: white;
  padding: 0;

  li {
    margin-bottom: 5px;
    padding: 4px 8px;
    
    // li should be the size of the text
    display: inline-block; 

    &.bg {
      &-orange {
        background: repairs-hub-colour('orange');
      }

      &-dark-green {
        background: repairs-hub-colour('dark-green');
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
