.operatives {
  .lbh-label {
    margin-top: 0px;
  }

  .lbh-label,
  .smv-read-only--label {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  &-group {
    @include govuk-media-query($from: tablet) {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:first {
        margin-top: 0px;
      }

      &:not(:first) {
        margin-top: 15px;
      }
    }
  }

  .smv-read-only {
    @include govuk-media-query($from: tablet) {
      display: inline-block;
      margin-left: 2em;
      background-color: transparent;
      margin-top: 0px;

      .smv-read-only--value {
        margin-top: 0.95em;
      }
    }

    @include govuk-media-query($until: tablet) {
      margin-top: 0.6em;
      background-color: lbh-colour('lbh-grey-4');
      padding: 0.8em 6px;
      p {
        margin: 0;
        display: inline-block;
        font-weight: 600;
      }
    }
    .smv-read-only--value {
      text-align: center;

      @include govuk-media-query($until: tablet) {
        margin-left: 2em;
      }
    }
  }

  .select-percentage {
    @include govuk-media-query($from: tablet) {
      display: inline-block;

      margin-left: 2em;
      margin-top: 0px;
    }

    @include govuk-media-query($until: tablet) {
      select {
        width: 50%;
      }
    }
  }

  .operative-datalist {
    @include govuk-media-query($from: tablet) {
      display: inline-block;
      margin-top: 0px;
    }
  }
}
