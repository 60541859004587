.lbh-header {
  @include govuk-media-query($until: desktop) {
    position: sticky;
    top: 0;
  }

  .lbh-header__service-name {
    font-family: $govuk-font-family;
  }

  .lbh-header__links {
    display: none;

    @include govuk-media-query($from: tablet) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    a {
      @include govuk-media-query($from: tablet) {
        margin: 0;
        padding: 0;

        margin-left: 20px;
      }
    }
  }

  .lbh-header__wrapper--stacked {
    display: flex;
  }

  .burger {
    cursor: pointer;
    display: inline-block;

    @include govuk-media-query($from: tablet) {
      display: none;
    }
  }

  .lbh-header__logo {
    @include govuk-media-query($until: tablet) {
      @include lbh-rem(width, 109);
      @include lbh-rem(height, 20);
    }
  }

  .lbh-header__title {
    @include govuk-media-query($until: tablet) {
      margin: 0;
    }

    @include govuk-media-query($from: tablet) {
      margin-right: 60px;
    }
  }

  .lbh-header__wrapper {
    align-items: center;
    justify-content: space-between;
  }

  .development-header .lbh-header__main {
    background-color: repairs-hub-colour('dark-green');
  }

  .lbh-header__logo-container,
  .lbh-header__service-name {
    display: inline-block;
    vertical-align: bottom;
  }

  .lbh-header__service-name {
    @include govuk-media-query($until: tablet) {
      padding-left: 10px;
      font-weight: 600;
    }
  }
}
