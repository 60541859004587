.aligned-row {
  padding-left: 2rem;
}

.govuk-table__cell {
  padding: 20px 20px 20px 0;
}

.govuk-table__header {
  font-weight: normal !important;
}
