$repairs-hub-colours: (
  'orange': lbh-colour('lbh-f04'),
  'dark-red': lbh-colour('lbh-f02'),
  'white': lbh-colour('lbh-white'),
  'black': lbh-colour('lbh-black'),
  'dark-grey': lbh-colour('lbh-grey-1'),
  'grey': lbh-colour('lbh-grey-2'),
  'light-grey': lbh-colour('lbh-grey-3'),
  'lighter-grey': lbh-colour('lbh-panel'),
  'lightest-grey': #e5e5e5,
  'lighest-lbh-grey': lbh-colour('lbh-grey-4'),
  'yellow': lbh-colour('lbh-h04'),
  'dark-green': lbh-colour('lbh-a01'),
  'light-green': lbh-colour('lbh-primary-disabled'),
  'blue': lbh-colour('lbh-link'),
  'lightest-blue': #e7f0f6,
);

@function repairs-hub-colour($colour) {
  @return map-get($repairs-hub-colours, quote($colour));
}

.text {
  &-green {
    color: repairs-hub-colour('dark-green');
  }

  &-white {
    color: repairs-hub-colour('white');
  }

  &-black {
    color: repairs-hub-colour('black');
  }

  &-dark-red {
    color: repairs-hub-colour('dark-red');
  }

  &-\!-white {
    color: repairs-hub-colour('white') !important;
  }
}

.background {
  &-yellow {
    background: repairs-hub-colour('yellow');
  }

  &-dark-green {
    background: repairs-hub-colour('dark-green');
  }
}
