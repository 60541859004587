$calendar-cell-width--mobile: 45px;
$calendar-cell-width--tablet: 75px;
$calendar-cell-width--desktop: 100px;

.appointment-calendar {
  table {
    border-collapse: collapse;
    border: 1px solid repairs-hub-colour('lighter-grey');
    margin: 0 0 10px 0;

    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCI+PHBhdGggZmlsbD0iI2Y3ZjdmNyIgZD0iTTAgMGgxMHYxMEgweiIvPjxwYXRoIGQ9Ik0tMSAxbDItMk0wIDEwTDEwIDBNOSAxMWwyLTIiIHN0cm9rZT0iI2RjZGNkYyIvPjwvc3ZnPg==');
    background-repeat: repeat;
    background-size: 7px 7px;

    user-select: none;

    caption {
      text-align: left;
    }

    thead {
      border-bottom: 2px solid repairs-hub-colour('grey');

      tr {
        border-top: 1px solid repairs-hub-colour('lighter-grey');

        th {
          background-color: repairs-hub-colour('white');
          font-size: 10px;
          text-transform: uppercase;
          padding: 5px;

          @include govuk-media-query($from: tablet) {
            font-size: 14px;
          }
        }
      }

      tr:first-child {
        th {
          font-size: 16px;
          text-transform: none;

          @include govuk-media-query($from: tablet) {
            font-size: 18px;
          }
        }
      }
    }

    td {
      border: 1px solid repairs-hub-colour('lighter-grey');
    }

    td {
      padding: 0;
      position: relative;

      div {
        box-sizing: border-box;
        cursor: not-allowed;

        height: 45px;
        width: $calendar-cell-width--mobile;

        &.available {
          background-color: repairs-hub-colour('white');
          cursor: pointer;
        }

        &.selected {
          background-color: repairs-hub-colour('light-green');
        }

        span.date {
          font-size: 10px;
          position: absolute;
          text-transform: uppercase;
          left: 3px;
          top: 3px;
        }

        span.month,
        span.today {
          color: repairs-hub-colour('white');
          font-size: 12px;
          font-weight: bold;
          text-align: center;
          position: absolute;
          width: 100%;
          bottom: 0;
          padding: 3px 0;
        }

        span.month {
          background-color: repairs-hub-colour('dark-grey');
        }

        span.today {
          background-color: repairs-hub-colour('dark-green');
        }

        @include govuk-media-query($from: tablet) {
          height: 60px;
          width: $calendar-cell-width--tablet;

          span.date {
            font-size: 12px;
          }

          span.month,
          span.today {
            font-size: 14px;
          }
        }

        @include govuk-media-query($from: desktop) {
          height: 75px;
          width: $calendar-cell-width--desktop;
        }
      }
    }
  }

  .legend {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 24px 0 0;
      padding: 0 0 0 24px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        border: 1px solid repairs-hub-colour('lighter-grey');
      }

      &.available {
        &::before {
          background-color: repairs-hub-colour('white');
        }
      }

      &.unavailable {
        &::before {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCI+PHBhdGggZmlsbD0iI2Y3ZjdmNyIgZD0iTTAgMGgxMHYxMEgweiIvPjxwYXRoIGQ9Ik0tMSAxbDItMk0wIDEwTDEwIDBNOSAxMWwyLTIiIHN0cm9rZT0iI2RjZGNkYyIvPjwvc3ZnPg==');
          background-repeat: repeat;
          background-size: 7px 7px;
        }
      }
    }
  }
}

.timeslot-form {
  background-color: repairs-hub-colour('light-grey');
  box-sizing: border-box;

  @include govuk-media-query($from: tablet) {
    width: calc(#{$calendar-cell-width--tablet} * 7 + 8px);
  }

  @include govuk-media-query($from: desktop) {
    width: calc(#{$calendar-cell-width--desktop} * 7 + 8px);
  }

  .button-pair {
    @include govuk-media-query($from: tablet) {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.govuk-textarea.appointment-text-area {
  @include govuk-media-query($from: desktop) {
    width: 50%;
  }
}
