.work-order-hours {
  padding-top: 0.5rem;
  color: repairs-hub-colour('dark-grey');
}

.hackney-work-order-table {
  tbody {
    vertical-align: top;
  }
  p {
    word-break: break-all;
  }
  p.description {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
}

.work-order-status {
  &.lbh-tag {
    &--status-in-progress {
      background-color: repairs-hub-colour('blue');
      color: repairs-hub-colour('white');
    }
    &--status-locked {
      background-color: repairs-hub-colour('white');
      color: repairs-hub-colour('black');
    }
    &--status-cancelled {
      background-color: repairs-hub-colour('dark-grey');
      color: repairs-hub-colour('white');
    }
    &--status-pending-approval,
    &--status-variation-pending-approval,
    &--status-authorisation-pending-approval {
      background-color: repairs-hub-colour('yellow');
      color: repairs-hub-colour('black');
      white-space: normal;
    }
    &--status-variation-approved {
      background-color: repairs-hub-colour('dark-green');
      color: repairs-hub-colour('white');
    }
    &--status-variation-rejected {
      background-color: repairs-hub-colour('dark-red');
      color: repairs-hub-colour('white');
    }
    &--status-closed,
    &--status-no-access {
      background-color: repairs-hub-colour('dark-grey');
    }
    &--status-completed,
    &--status-work-completed {
      background-color: repairs-hub-colour('white');
      color: repairs-hub-colour('black');
      border: 2px solid black;
    }
  }
}

.work-orders-history-table {
  tbody {
    vertical-align: top;
  }

  td {
    white-space: nowrap;
    &.description {
      white-space: normal;
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.manage-work-orders {

  margin: 0;

  .lbh-body-xs {
    line-height: 19px !important;
    font-size: 14px !important;
  }
}
