.rate-schedule-item--sor-code {
  @include govuk-media-query($from: tablet) {
    width: 66.66666%;
    display: inline-block;
  }
}

.rate-schedule-item--quantity {
  display: inline-block;

  @include govuk-media-query($from: tablet) {
    padding-left: 15px;
    width: 120px;
  }
}

.rate-schedule-item > div {
  box-sizing: border-box;
}

.remove-rate-schedule-item {
  display: inline-block;
  padding-left: 15px;
}
